<template>
  <div class="home">
    <el-container>
      <el-header style=" height:80px;" class="top-box">
        <el-row  :gutter="24">
          <el-col  :span="2" style="text-align: center">
            <img class="logo" src="../assets/logo.png" alt="" />
          </el-col>
          <el-col :span="18">
            <h1 style="color: #4bb8b3;">生美服务平台</h1>
          </el-col>
          <el-col :span="4"
            ><div class="info-box">
              <div class="info-image">
                <img class="touxiang" src="../assets/11.png" alt="" />
              </div>
              <div class="info-message">
                <strong >{{data.userName}}</strong>
                <el-button class="out-ptn" @click="tuichu()" type="text">退出</el-button>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-header>
      <el-container>
        <el-aside width="215px" style="text-align: center">
          <!--  -->
          <el-menu :default-active="data.activeIndex" router="true"  class="el-menu-vertical-demo">
            <template v-for="(i, index) in tabList">
              <!--  @click="handleChangeMenu(i.path)"-->
            <el-menu-item  v-if="i.meta.isShow" :key="i.name" :router="i.path"  :index="i.name">
              <i :class="i.meta.icon"></i>
              <template #title>{{ i.meta.title }}</template>
            </el-menu-item>
            </template>
          </el-menu>
        </el-aside>
        <el-main>
          <router-view>
          </router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
// @ is an alias to /src
//import {reactive} from "vue";
import router from "../router/index";
import {reactive} from "vue"
import Cookies from 'js-cookie'
export default {
  name: "Home",
  setup() {
    let tabList = router.options.routes[0].children;
    //console.log(tabList);
    let tuichu=()=>{
      Cookies.set('name','');
      Cookies.set('userName','')
      router.push("/");
    }
    let data = reactive({
      userName: Cookies.get('userName'),
      activeIndex: ''
    });
    let updateRoute = () => {
      let index = tabList.findIndex(item => {
        return item.path == router.currentRoute._value.fullPath;
      })
      data.activeIndex = index;
    }
     updateRoute();
    // console.log(router.currentRoute._value.fullPath);
    // tabList = tabList.filter((t) => t.meta.isShow);
    let handleChangeMenu = (path) => {
      console.log('handleChangeMenu');
      router.replace({
        path: path
      })
    }
    return {
      tabList,
      tuichu,
      data,
      handleChangeMenu,
      updateRoute
    };
  },
  watch: {
    $route(newRoute) {
      // console.log('更换路由了');
    //  this.updateRoute();
    }
  },
};
</script>
<style scoped>
.el-aside {
  overflow-y: auto;  /* 添加垂直滚动条 */
  max-height: 100vh; /* 设置最大高度为视窗高度 */
}
/* 其他样式保持不变 */
.top-box {
  height: 100px;
  background-color: #efefef;
  padding-top: 10px;
  padding-bottom: 10px;
}
.top-box {
  height: 100px;
  background-color: #efefef;
  padding-top: 10px;
  padding-bottom: 10px;
}
.logo {
  /*width: 50px; */
  height: 60px;
}
.info-box {
  margin-top:10px;
}
.out-ptn {
  margin-left: 20px;
  font-weight: 500;
  font-size: 17px;
}
.touxiang{
  margin-right: 30px;
}
.info-image{float: left; border: 0px solid red; }
.logo {
  border-radius: 50%; overflow: hidden
}
.info-message{float: left; border: 0px solid orange;}
/* .el-menu-item.is-active {
  color: #4bb8b3;
  font-weight: 800;
}
.el-button--primary {
  background: #4bb8b3!important;
} */
</style>
