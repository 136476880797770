<template>
    <div class="wrapper loginBody">
        <div class="content clearfix">
            <div class="form fr">
                <div class="name"><span>生美</span>服务平台</div>

                <div class="form-box">
                    <div class="form-box-hd">密码登录</div>
                    <div id="form2">
                        <div class="form-input">
                            <span class="label">用户名</span>
                            <input v-model=" loginData.username"  type="text" placeholder="请输入用户名">
                        </div>
                        <div class="form-input">
                            <span class="label">密&nbsp;&nbsp;&nbsp;码</span>
                            <input v-model="loginData.password" type="password" placeholder="请输入密码">
                        </div>
                    </div>
                  <button style="cursor: pointer;" @click="subFun()">登录</button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
 import { reactive } from "vue";
 import { ElMessage } from "element-plus";
 //import { login } from "../http/api";
 import router from "../router/index.js";
  import axios from 'axios';
  import qs from 'qs';
  import Cookies from 'js-cookie'
 import {PLATFROM_CONFIG} from '../../public/config'
// 跨域设置


export default {
  name: "Login",
  setup() {


    let loginData = reactive({
      username: "",
      password: "",
      grant_type:"password",
      client_id:"Skin_App",
      client_secret:"1q2w3e*"
    });

    let trim =(s)=>{
    return s.replace(/(^\s*)|(\s*$)/g, "");
    }

    let subFun = () => {
      loginData.username = trim(loginData.username);
      loginData.password = trim(loginData.password);
      if (!loginData.username || !loginData.password) {
        ElMessage({
          showClose: true,
          message: '用户名或密码不能为空!',
          type: 'error',
          duration:2000
        });
        return;
    }
        //这个登录要拿出去
        let options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(loginData),   // 用 qs 将js对象转换为字符串 'name=edward&age=25'
            url: PLATFROM_CONFIG.baseURL+'/connect/token'
        };

        axios(options).then((res) => {
            console.log(res.data);
            //将用户信息保存到cookie中
            Cookies.set('name', res.data.access_token, { expires: 90 });
            Cookies.set('userName',loginData.username,)
            console.log(Cookies.get('name'));
            router.push('/home')

        }).catch(error => {
            console.log(error.response);
            ElMessage({
                showClose: true,
                message: '用户名或密码错误!',
                type: 'error',
                duration:2000
            });

        // if (error.response) {
        //     console.log('后台错误');
        //     console.log(error.response.data);
        //     console.log(error.response.status);
        //     console.log(error.response.headers);
        // } else if (error.request) {
        //     console.log('前台传参错误');
        //     console.log(error.request);
        // } else {
        //     console.log('Error', error.message);
        // }
        // console.log(error.config);

    });

    };
    return {
      loginData,
      subFun,
    };
  }
};
</script>

<style scoped>
/* @font-face{
    font-family: 'PingFang';
    src: url("https://mansoo-aig.obs.cn-north-4.myhuaweicloud.com/CampMini/images/.ttf");
} */

.loginBody {
    width: 100%;
    min-width: 1300px;
    height: 100%;
    background: url( ../images/background.png) no-repeat center 100%;
    background-size: cover;
    font-family: PingFang;
}

.wrapper {
    width: 100%;
    min-width: 1300px;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .content {
    width: 1270px;
    height: 612px;
    position: absolute;
    top: 180px;
    left: 50%;
    margin-left: -635px;
} */

.content .desc {
    margin-top: 110px;
}

.content .form {
    width: 440px;
	height: 469px;
	background-color: #fefefe;
	box-shadow: 4px 6px 9px 1px rgba(0, 0, 0, 0.07);
	border-radius: 5px;
    overflow: hidden;
    /* width: 440px;
    height: 530px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 2px 2px 2px rgba(202 ,190 ,190 ,.3);
    padding-top: 30px; */
}

.form .name {
    /* margin-bottom: 65px; */
    height: 112px;
    line-height: 140px;

    font-size: 24px;
    font-weight: 800;
    color: #3c3c3c;
    text-align: center;

    background-color: #f6fbfe;
}

.form .name span {
    color: #3cdbcb;
}

.form .form-box {
    padding: 0 31px ;
}

.form .form-box .form-box-hd {
    height: 103px;
    color: #333;
    font-size: 16px;
    line-height: 130px;
}

.form-box .tab {
    margin-bottom: 18px;
}

.form-box .tab li {
    height: 64px;
    float: left;
    margin-right: 26px;
}

.tab a {
    font-size: 16px;
    line-height: 64px;
    font-family: PingFang;
}

.active a {
    font-weight: 800;
}

.active:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 4px;
    background-color: #f8cd00;
    position: relative;
    top: -16px;
}

.form {
    color: #8493ac;
}


.form .form-input {
    height: 38px;
    border: 1px solid #f0f0f0;
    margin-bottom: 20px;
    padding: 0 38px;
    font-family: MicrosoftYaHei;
	font-size: 14px;
    color: #333333;
    /* background-color: #f9f9fb; */
}

.form .form-input .label {
    display: inline-block;
    border-right: 1px solid #dddddd;
    padding-right: 20px;
}

.form .form-input input {
    height: 38px;
    color: #010101;
    padding-left: 20px;
    border-bottom: 1px solid #f0f0f0;
    font-family: MicrosoftYaHei;
	font-size: 14px;
    color: #999;
}

.form .line {
    margin: 0 10px;
    color: #f0f0f0;
}

.register {
    display: block;
    margin: 28px 0;
    text-align: right;
    font-family: MicrosoftYaHei;
	font-size: 12px;
	letter-spacing: 0px;
	color: #3cdbcb;
}

.message {
    display: flex;
}

.message input,
.message a {
    flex: 1;
}

.message a {
    text-align: right;
    line-height: 56px;
    color: #304692;
    font-size: 15px;
}

.form button {
    width: 100%;
	height: 39px;
    line-height: 39px;
	background-color: #41decc;
	border-radius: 5px;
    color: #fff;
    border: 0;
    font-size: 16px;
}

.otherlogintype {
    width: 503px;
    display: flex;
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    position: absolute;
    bottom: 20px;
    right: 0;
    padding: 0 54px;
    color: #8493ac;
}

.otherlogintype div {
    flex: 1;
}

.option {
    margin-right: 27px;
    display: inline-block;
    float: right;
    cursor: pointer;
}

.option:first-child {
    margin-right: 0;
}

.option img {
    height: 18px;
    margin-right: 10px;
    position: relative;
    top: 5px;
}

/*忘记密码*/
.memory {
    display: flex;
    justify-content: space-between;
    color: #697279;
    font-size: 14px;
    padding: 20px 0;
}

.memory input {
    display: inline-block;
    width: 40px;
    height: 40px;
    border: 1px solid #697279;
}

.memory a {
    color: #697279;
}


/*=======reset css=======*/
*,
*::before,
*::after { /*所有的标签和伪元素都选中*/
    margin: 0;
    padding: 0;

    box-sizing: border-box; /*有效防止内容溢出，出现滚动条*/
    -webkit-box-sizing: border-box;

    /*点击高亮效果的清除*/
    tap-highlight-color: transparent;
    -webkit-tap-highlight-color: transparent;
}

body {
    font-size: 14px;
    font-family: "Microsoft YaHei", sans-serif;
    color: #333333;
}

ul,
ol {
    list-style: none;
}

a {
    color: #333333;
    text-decoration: none;
}

input,
textarea {
    border: none;
    outline: none;

    /*不允许改变尺寸*/
    resize: none;

    /*元素的外观 none 没有任何样式 移动端特有*/
    -webkit-appearance: none;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.clearfix:after {
    content: "";
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
}

.clearfix {
    *zoom: 1;  /*IE6、7专门清除浮动的样式*/
}

.hide {
    display: none;
}

</style>
