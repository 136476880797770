import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";



const routes = [
  {
    path: "/Home",
    name: "Home",
    component: Home,
    redirect:"/ShopBanner",
    meta: {
      isShow: false,
    },
    children: [
      {
        path: "/BannerManagement",
        name: "BannerManagement",
        meta: {
          isShow: true,
          title: "广告管理",
          icon:"el-icon-document"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/BannerManagement.vue"),
      }, {
        path: "/ApplicationProject",
        name: "ApplicationProject",
        meta: {
          isShow: false,
          title: "报名详情",
          icon:"el-icon-document"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/ApplicationProject.vue")
      }, {
        path: "/UserCoupons",
        name: "UserCoupons",
        meta: {
          isShow: false,
          title: "领券详情",
          icon:"el-icon-document"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/UserCoupons.vue")
      },{
        path: "/RecommendFriend",
        name: "RecommendFriend",
        meta: {
          isShow: false,
          title: "推荐好友详情",
          icon:"el-icon-document"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/RecommendFriend.vue")
      },{
        path: "/ShopBanner",
        name: "ShopBanner",
        meta: {
          isShow: true,
          title: "门店广告",
          icon:"el-icon-office-building"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/BannerShop.vue"),
      }, {
        path: "/CityBanner",
        name: "CityBanner",
        meta: {
          isShow: true,
          title: "城市广告",
          icon:"el-icon-school"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/BannerCity.vue"),
      },
      {
        path: "/BannerDescImg",
        name: "BannerDescImg",
        meta: {
          isShow: false,
          title: "广告详图",
          icon:"el-icon-money"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/BannerDescImg.vue")
      },
      {
        path: "/Shop",
        name: "Shop",
        meta: {
          isShow: true,
          title: "门店管理",
          icon:"el-icon-s-shop"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/Shop.vue"),
      },
      {
        path: "/Order",
        name: "Order",
        meta: {
          isShow: true,
          title: "订单查询",
          icon:"el-icon-notebook-1"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/Order.vue")
      },
      {
        path: "/Goods",
        name: "Goods",
        meta: {
          isShow: true,
          title: "商品管理",
          icon:"el-icon-shopping-bag-1"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/Goods.vue")
      },
      {
        path: "/GoodsImg",
        name: "GoodsImg",
        meta: {
          isShow: false,
          title: "商品图片",
          icon:"el-icon-money"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/GoodsImg.vue")
      },
      {
        path: "/GoodsDescImg",
        name: "GoodsDescImg",
        meta: {
          isShow: false,
          title: "商品详图",
          icon:"el-icon-money"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/GoodsDescImg.vue")
      },
      {
        path: "/NumberCard",
        name: "NumberCard",
        meta: {
          isShow: true,
          title: "次卡管理",
          icon:"el-icon-bank-card"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/NumberCard.vue")
      },
      {
        path: "/Product",
        name: "Product",
        meta: {
          isShow: true,
          title: "产品管理",
          icon:"el-icon-box"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/Product.vue")
      },
      {
        path: "/ServiceItem",
        name: "ServiceItem",
        meta: {
          isShow: true,
          title: "服务管理",
          icon:"el-icon-news"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/ServiceItem.vue")
      },
      {
        path: "/Complaint",
        name: "Complaint",
        meta: {
          isShow: true,
          title: "客户投诉",
          icon:"el-icon-edit-outline"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/Complaint.vue")
      },{
        path: "/Member",
        name: "Member",
        meta: {
          isShow: true,
          title: "会员管理",
          icon:"el-icon-user-solid"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/Member.vue")
      },
      {
        path: "/ComplaintType",
        name: "ComplaintType",
        meta: {
          isShow: true,
          title: "投诉类型",
          icon:"el-icon-s-opportunity"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/ComplaintType.vue")
      },
      {
        path: "/City",
        name: "City",
        meta: {
          isShow: true,
          title: "城市管理",
          icon:"el-icon-basketball"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/City.vue"),
      },
      {
        path: "/Category",
        name: "Category",
        meta: {
          isShow: true,
          title: "商品分类",
          icon:"el-icon-s-flag"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/Category.vue")
      },
      {
        path: "/BackUser",
        name: "BackUser",
        meta: {
          isShow: true,
          title: "用户管理",
          icon:"el-icon-user"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/BackUser.vue")
      },
      {
        path: "/ServicePhone",
        name: "ServicePhone",
        meta: {
          isShow: true,
          title: "客服电话",
          icon:"el-icon-phone-outline"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/ServicePhone.vue")
      },
      {
        path: "/EvaluationConfiguration",
        name: "EvaluationConfiguration",
        meta: {
          isShow: true,
          title: "评价配置",
          icon:"el-icon-edit-outline"
        },
        component: () =>
            import("../views/EvaluationConfiguration.vue"),
      },
      {
        path: "/NoteConfiguration",
        name: "NoteConfiguration",
        meta: {
          isShow: true,
          title: "注意事项",
          icon:"el-icon-edit-outline"
        },
        component: () =>
            import("../views/NoteConfiguration.vue"),
      },
      {
        path:"/ChangePassword",
        name:"ChangePassword",
        meta: {
          isShow: true,
          title: "密码模板",
          icon: "el-icon-lock"
        },
        component: () =>
            import("../views/ChangePassword")
      },
      {
        path:"/ReturnVisit",
        name:"ReturnVisit",
        meta: {
          isShow: true,
          title: "回访模板",
          icon: "el-icon-info"
        },
        component: () =>
            import("../views/ReturnVisit")
      },{
        path:"/ScoreAndSwipeCard",
        name:"ScoreAndSwipeCard",
        meta: {
          isShow: true,
          title: "划卡模板",
          icon: "el-icon-minus"
        },
        component: () =>
            import("../views/ScoreAndSwipeCard")
      },
      {
        path: "/ServiceEvaluation",
        name: "ServiceEvaluation",
        meta: {
          isShow: true,
          title: "评价列表",
          icon:"el-icon-user"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/ServiceEvaluation.vue")
      },
      {
        path: "/Payment",
        name: "Payment",
        meta: {
          isShow: true,
          title: "支付配置",
          icon:"el-icon-open"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/Payment.vue")
      },
      {
        path: "/Pointsexchangetype",
        name: "Pointsexchangetype",
        meta: {
          isShow: false,
          title: "积分兑换分类",
          icon:"el-icon-s-order",
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/Pointsexchangetype.vue")
      },
      {
        path: "/PointsCoupons",
        name: "PointsCoupons",
        meta: {
          isShow: false,
          title: "积分及优惠券配置",
          icon:"el-icon-coin"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/PointsCoupons.vue")
      },
      {
        path: "/PointsMall",
        name: "PointsMall",
        meta: {
          isShow: true,
          title: "积分商城",
          icon:"el-icon-shopping-bag-1"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/PointsMall.vue")
      },
      {
        path: "/vipequity",
        name: "vipequity",
        meta: {
          isShow: true,
          title: "权益列表",
          icon:"el-icon-s-order"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/vipequity.vue")
      },
      {
        path: "/vipmanage",
        name: "VipManage",
        meta: {
          isShow: true,
          title: "会员等级配置",
          icon:"el-icon-finished"
        },
        component: () =>
            import(/* webpackChunkName: "vipmanage" */ "../views/VipManage.vue")
      },
      {
        path: "/vipblurb",
        name: "VipBlurb",
        meta: {
          isShow: false,
          title: "会员等级简介",
          icon:"el-icon-edit-outline"
        },
        component: () =>
            import(/* webpackChunkName: "vipmanage" */ "../views/VipBlurb.vue")
      },
      {
        path: "/vipblurbtext",
        name: "vipblurbtext",
        meta: {
          isShow: false,
          title: "会员等级简介",
          icon:"el-icon-edit-outline"
        },
        component: () =>
            import(/* webpackChunkName: "vipmanage" */ "../views/vipblurbtext.vue")
      },
      {
        path: "/LotteryRuleConfig",
        name: "LotteryRuleConfig",
        meta: {
          isShow: true,
          title: "九宫格抽奖规则配置",
          icon:"el-icon-present"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/LotteryRuleConfig.vue")
      },
      {
        path: "/PointsMallImg",
        name: "PointsMallImg",
        meta: {
          isShow: false,
          title: "商品详图",
          icon:"el-icon-money"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/PointsMallImg.vue")
      },
      {
        path: "/ExchangeDetails",
        name: "ExchangeDetails",
        meta: {
          isShow: false,
          title: "兑换明细",
          icon:"el-icon-money"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/ExchangeDetails.vue")
      },
      {
        path: "/TaskManage",
        name: "TaskManage",
        meta: {
          isShow: false,
          title: "任务配置",
          icon:"el-icon-s-operation"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/TaskManage.vue")
      },
      {
        path: "/TaskCollectionDetails",
        name: "TaskCollectionDetails",
        meta: {
          isShow: false,
          title: "任务领取明细",
          icon:"el-icon-operation"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/TaskCollectionDetails.vue")
      },
      {
        path: "/Drawrecords",
        name: "Drawrecords",
        meta: {
          isShow: false,
          title: "抽奖记录",
          icon:"el-icon-finished"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/Drawrecords.vue")
      },
      {
        path: "/TotalTaskManage",
        name: "TotalTaskManage",
        meta: {
          isShow: true,
          title: "任务配置",
          icon:"el-icon-finished"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/TotalTaskManage.vue")
      },
      {
        path: "/Prizeconfig",
        name: "Prizeconfig",
        meta: {
          isShow: false,
          title: "奖品配置表",
          icon:"el-icon-finished"
        },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/Prizeconfig.vue")
      },
    ]
  },


  {
    path: "/",
    name: "Login",
    meta: {
      isShow: false,
      title: "生美服务平台",
    },
    component: Login,
    // component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  }
];


const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
